import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Alert, Spinner, Image, Modal } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits, parseUnits, parseEther } from 'ethers'
import NodesABI from '../abis/NodesABI';
import CollectionABI from '../abis/CollectionABI';
import SETTINGS from '../SETTINGS';
import { ERC20_ABI } from "../abis/erc20";
import { useContract } from '../hooks/useContract';
import logoToken from '../logoToken.svg';
import { useNavigate } from 'react-router-dom';
import ConnectButton from '../components/ConnectButton';
import SuccessModal from '../components/SuccessModal';
import ReferralButton from '../components/ReferralButton';
import Select from 'react-select';
import { BiGift, BiStar } from 'react-icons/bi';
import governanceImg from '../governance.png';

const Nodes = ({ provider, account, isConnected, networkId, switchNetwork }) => {
    const [quantities, setQuantities] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
    const [quantities1, setQuantities1] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
    const [nodeTypes, setNodeTypes] = useState([10, 20, 30, 40, 50, 60, 70, 80]);
    const [nodeTypes1, setNodeTypes1] = useState([1, 2, 3, 4, 5, 6]);
    const [tokenBalances, setTokenBalances] = useState({});
    const { collections, fetchCollections, coreRewards, NFTTypeCount, coreRewardsAmount, fetchOwnedNFTs } = useContract(provider);
    const [loading, setLoading] = useState(false);
    const [buyAvailable, setBuyAvailable] = useState(false);
    const [txMessage, setTxMessage] = useState('');
    const [reffCode, setReffCode] = useState('');
    const [reffAddress, setReffAddress] = useState('');
    const [usdtBalance, setUsdtBalance] = useState('0.00');
    const [usdtAllowance, setUsdtAllowance] = useState(0);
    const [waveBalance, setWaveBalance] = useState('0.00');
    const [waveAllowance, setWaveAllowance] = useState(0);
    const [userLevel, setUserLevel] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState(0); // Default currency is USDC
    const [modalShow, setModalShow] = useState(false);

    const [modalContent, setModalContent] = useState(null);
    const [isMobile] = useState(false);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = (level) => {
    const content = SETTINGS.NODE_INFO[level]; // Assuming content is in SETTINGS.NODE_INFO
    console.log(content);
    setModalContent(content);
    setShow(true);
    };
    const openBuyNode = () => {
        navigate('/store');
    }
    const openCraft = () => {
      navigate('/craft');
    }


    const handleCurrencyChange = (selectedOption) => {
        console.log(selectedOption.value);
        setSelectedCurrency(selectedOption.value);
       
    };
    
    const getUserLevel = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const collectionContract = new Contract(
            SETTINGS.collection,
            CollectionABI,
            signer
        );
        const level = await collectionContract.levels(signer.address);
       
        if (parseInt(level) > 0) {
            setUserLevel(parseInt(level));
        }
    };

    async function getBalance() {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
    
        try {
            // Define contract instances
            const USDTContract = new Contract(SETTINGS.usdtAddr, ERC20_ABI, signer);
            const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
            const NodesContract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
    
            // Create promises for USDT and Token balances and allowances
            const USDTBalancePromise = USDTContract.balanceOf(account);
            const USDTAllowancePromise = USDTContract.allowance(account, SETTINGS.nodeContract);
            const TokenBalancePromise = TokenContract.balanceOf(account);
            const TokenAllowancePromise = TokenContract.allowance(account, SETTINGS.nodeContract);
    
            // Collect all node token balance promises in parallel
            const nodeTokenPromises = nodeTypes.map(async (nodeType) => {
                const tknContract = new Contract(SETTINGS.NODE_INFO[nodeType].buyToken, ERC20_ABI, signer);
                const tknBalance = await tknContract.balanceOf(account);
                const tknDecimals = await tknContract.decimals();
                return { nodeType, balance: parseFloat(formatUnits(tknBalance, tknDecimals)).toFixed(2) };
            });
    
            // Collect the promises into an array
            const [
                USDTBalance,
                USDTAllowance,
                TokenBalance,
                TokenAllowance,
                nodeTokenResults,
                savedReferral
            ] = await Promise.all([
                USDTBalancePromise,
                USDTAllowancePromise,
                TokenBalancePromise,
                TokenAllowancePromise,
                Promise.all(nodeTokenPromises), // Wait for all node token balance promises to resolve
                NodesContract.my_referrer(account) // Fetch referral address
            ]);
    
            // Set state with results
            setUsdtBalance(parseFloat(formatUnits(USDTBalance, 18)).toFixed(2));
            setUsdtAllowance(parseFloat(USDTAllowance));
            setWaveBalance(parseFloat(formatUnits(TokenBalance, 18)).toFixed(2));
            setWaveAllowance(parseFloat(formatUnits(TokenAllowance, 18)));
    
            // Create and set the token balances dictionary
            const tempDict = nodeTokenResults.reduce((acc, { nodeType, balance }) => {
                acc[nodeType] = balance;
                return acc;
            }, {});
            setTokenBalances(tempDict);
    
            // Check referral and update state accordingly
            if (savedReferral.toLowerCase() !== "0x0000000000000000000000000000000000000000") {
                setBuyAvailable(true);
                setReffAddress(savedReferral);
            }
    
            // Execute the user level check in parallel
            await getUserLevel();
        } catch (error) {
            console.log("Error in getBalance:", error);
        }
    };
    



    useEffect(() => {
        if (isConnected && parseInt(networkId) == 137) {
            getBalance();
        }
    }, [isConnected]);

    const incrementQuantity = (index) => {
        setQuantities(qts => qts.map((qty, idx) => idx === index ? qty + 1 : qty));
    };

    const decrementQuantity = (index) => {
        setQuantities(qts => qts.map((qty, idx) => idx === index && qty > 1 ? qty - 1 : qty));
    };
    const incrementQuantity1 = (index) => {
        setQuantities1(qts => qts.map((qty, idx) => idx === index ? qty + 1 : qty));
    };

    const decrementQuantity1 = (index) => {
        setQuantities1(qts => qts.map((qty, idx) => idx === index && qty > 1 ? qty - 1 : qty));
    };


    const _setReffCode = async (_code) => {
        const code = _code.toUpperCase();
        setReffCode(code);
        if (code.length <= 3) return;
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const collectionContract = new Contract(
            SETTINGS.collection,
            CollectionABI,
            signer
        );
        // Call the buy function on the NFT contract
        // Since we're only working with USDT, we don't need to send ETH value
        const refferalAddr = await collectionContract.codeUser(code);
        if (refferalAddr.toLowerCase() === "0x0000000000000000000000000000000000000000") {
            setBuyAvailable(false);
        } else {
            if (refferalAddr.toLowerCase() == signer.address.toLowerCase()) {
                alert("You can't reffer yourself!");
                setBuyAvailable(false);
            } else {
                setBuyAvailable(true);
            }

        }
        setReffAddress(refferalAddr);
    };
    
    const buyNFT = async (index) => {
       
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
      
      
        const quantity = quantities[index];
        
        const tokenAmountDec = SETTINGS.NODE_INFO[nodeTypes1[index]].price * parseInt(quantity);
        const tokenAmount = parseUnits(tokenAmountDec.toString(), 18);
        console.log(selectedCurrency);
        console.log(coreRewards);
        if(parseInt(coreRewards[selectedCurrency]) <= 0){
            alert("Not enough COREs");
            return;
        }
        try {
            setLoading(true);
            
            if (tokenAmount > usdtAllowance) {
                setTxMessage("Approving WAVE transfer...");
            const usdtContract = new Contract(
                SETTINGS.usdtAddr,
                ERC20_ABI,
                signer
            );
            // Approve the NFT contract to spend USDT on behalf of the user
            const approveTx = await usdtContract.approve(
                SETTINGS.nodeContract,
                tokenAmount
            );
            await approveTx.wait();
        }
            setTxMessage("Processing the purchase...");
            // Connect to the NFT contract and buy the NFT
            const nftContract = new Contract(
                SETTINGS.nodeContract,
                NodesABI,
                signer
            );
            // Call the buy function on the NFT contract
            // Since we're only working with USDT, we don't need to send ETH value
            const buyTx = await nftContract.buy(
                quantity, 
                tokenAmount, 
                reffAddress, 
                nodeTypes1[index], 
                selectedCurrency, 
                0, // tokenId set to 0 to skip NFT transfer
                {
                    value: parseEther("0.1"), // Ensure this matches `FEE` in the contract
                    gasLimit: 700000 // Increase gas limit to ensure complex operations are covered
                }
            );
            
            await buyTx.wait();
            if(parseInt(index) > 0){
                coreRewards[selectedCurrency] = (parseInt(coreRewards[selectedCurrency]) - 1).toString();
            }
            await fetchOwnedNFTs();
            setTxMessage("NFT successfully purchased!");
            setModalShow(true);
            const newLevel = userLevel + 1;
            setUserLevel(newLevel);
        } catch (error) {
            console.error("Error purchasing NFT:", error);
            setTxMessage("Failed to purchase NFT. Please try again.");
        } finally {
            await fetchCollections();
            setLoading(false);
            
            setTimeout(() => setTxMessage(""), 5000);

        }
    };

    const currencyOptions = nodeTypes.map(key => {
        const node = SETTINGS.NODE_INFO[key];
        return {
            value: key,
            label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                   
                    <img 
                        src={node?.imgUrl} 
                        alt={node.title} 
                        style={{ width: '25px', marginRight: '8px', borderRadius:'6px' }} 
                    />
                    <span style={{ fontSize: '14px', marginLeft:"2px" }} ><b>{node.title}</b> </span>
                    <b style={{ fontSize: '14px', marginLeft:"4px" }} >
                        x {NFTTypeCount[key]} ⎮
                        <img src={governanceImg} className='tokenIconColor' style={{ width: "20px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt="Parachute Icon" />
                        x {coreRewards[key]}</b>
                </div>
            )
        };
    });

    const buyCard = async (type, index) => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const quantity = quantities1[index];
        const waveAmount = SETTINGS.NODE_INFO[type].price * parseInt(quantity);
        const waveAmountWei = parseUnits(waveAmount.toString(), 18);
        
        try {
            setLoading(true);
           
                
                // Connect to the USDT contract
                const waveContract = new Contract(
                    SETTINGS.NODE_INFO[type].buyToken,
                    ERC20_ABI,
                    signer
                );
                const waveAllowance = await waveContract.allowance(account, SETTINGS.nodeContract);
                if (waveAmountWei > waveAllowance) {
                    setTxMessage("Approving transfer...");
                    // Approve the NFT contract to spend USDT on behalf of the user
                    const approveTx = await waveContract.approve(
                        SETTINGS.nodeContract,
                        waveAmountWei
                    );
                    await approveTx.wait();
                }
            

            setTxMessage("Processing the purchase...");
            // Connect to the NFT contract and buy the NFT
            const nftContract = new Contract(
                SETTINGS.nodeContract,
                NodesABI,
                signer
            );
            // Call the buy function on the NFT contract
            // Since we're only working with USDT, we don't need to send ETH value
            const buyTx = await nftContract.buy_card(quantity, waveAmountWei, type, SETTINGS.NODE_INFO[type].buyToken, 
                {
                    value: parseEther("0.1"), // Ensure this matches `FEE` in the contract
                    gasLimit: 700000 // Increase gas limit to ensure complex operations are covered
                });
            await buyTx.wait();
            setTxMessage("NFT successfully purchased!");
        } catch (error) {
            console.error("Error purchasing NFT:", error);
            setTxMessage("Failed to purchase NFT. Please try again.");
        } finally {
            await fetchCollections();
            setLoading(false);
            setModalShow(true);
            setTimeout(() => setTxMessage(""), 5000);
        }
    };

    const handleSwitchNetwork = async () => {
        await switchNetwork(137);
       
    };

    if (networkId != "137" && !SETTINGS.IS_TEST)  {
        return (
            <div className=" text-center">
                <div className="network-switch-section" style={{marginTop:"150px"}}>
                 
                      <p>Switch to Polygon network:</p>
                      <ConnectButton />
                    </div>
            </div>
        );
    }


    const customSelectStyles = {
        control: (provided) => ({
            ...provided,
            fontSize: '14px',
            color: 'var(--tertiary)',
            border: '2px solid rgba(0, 0, 0, 0.1)',
            background: 'rgba(149, 77, 255, 0.01)',
            padding: '5px 8px',
            borderRadius: '8px',
            width: '100%',
            textAlign: 'center',
            zIndex: 999999,
            transition: 'all 0.3s ease-in-out',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            fontSize: '14px',
            alignItems: 'center',
            zIndex: 999999,
        }),
        menu: (provided) => ({ ...provided, zIndex: 999999, fontSize: '12px', })
     
    };

    return (
        <div className="buy-token-container1 page-12 mb-4 text-center page-container">
                {(loading ||  collections.length === 0 && isConnected) && (
      <div className="gameLoader">
        <br />
        <br />
        <br />

        <br />
        <div className="gameSpinner">
        <Spinner animation="border" role="status" className="loaderBig" />
        <center>
                        <p>
                           {txMessage}
                        </p>
                      </center>
        </div>
        </div>
    )}
            <h3 className='liqnodes-header'>
            Starter Power Core Package
            </h3>
            <p className="hero-p">Jumpstart your Liquidity Nodes with limited Power Core packages for continuous WaveCoin rewards.</p>
            <Row className="mt-0 mb-md-4 mb-0 justify-content-md-center justify-content-start">
            {Object.keys(coreRewards).map((key) => (
                <Col 
                xs={4} // Take 4 columns on on extra-small devices (portrait phones)
                sm={3} // Take 3 columns on small devices (landscape phones)
                md={2} // Take 2 columns on medium devices (tablets)
                lg={1} // Take 1 columns on large devices (desktops)
                className="mb-3" 
                key={key} 
                style={{ padding: "0 8px" }}
                >
                <h6 className='mb-md-0 mb-3 market-header' style={{ textAlign: "center" }}>
                {SETTINGS.NODE_INFO[key]?.title ? <span>{SETTINGS.NODE_INFO[key].title}</span> : <span>Core</span>}

                </h6>
                <img 
                    variant="top" 
                    src={SETTINGS.NODE_INFO[key]?.imgUrl} 
                    style={{ width: "100%", borderRadius: "10px" }} 
                    alt=""
                />
                <div className='craftNumber' style={{ fontSize: '14px' }}>x {coreRewards[key]}</div>
                </Col>
            ))}
            
            {/* WAVE balance */}
            <Col xs={12} className="mb-1 d-flex justify-content-center align-items-center" style={{ padding: "0 8px" }}>
                <span style={{ marginTop: "6px", display: "block", fontWeight: "400" }}>
               {/*<small>
                    <img src={logoToken} className='tokenIconColor' style={{ width: "20px", marginRight: "4px", marginLeft: "4px", marginTop: "-2px" }} alt={"WAVE"} /> 
                    Balance: 
                </small>
                <small className='fw-bold'>
                    {new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(usdtBalance)} {SETTINGS.tokenSymbol}
                </small> <br/>*/}
                {coreRewardsAmount} Remaining Power Core Starter Packages
                </span> 
            </Col>
            </Row>
            <hr />
            <h2 className='liqnodes-header'>Liquidity Node Collection</h2>
            <p className="hero-p sub-header">Build your portfolio with Liquidity Node Collection, designed to generate daily rewards and enhance token liquidity.</p> 
            <Row className="mb-5 pb-md-0 pb-4 pt-md-0 pt-0">
                {/* Buy NODE */}
                {nodeTypes1.map((type, index, level) => (
                    <Col xs={12} md={6} lg={4} xl={4} className='mb-md-4 mb-3' key={index}>
                        <div>
                            <div className='pokemon-card pokemon-card-height d-flex flex-column justify-content-between'>
                                <div className="pokemon-card-title pt-0 pb-1">
                                    <h4 className='mb-md-0 market-header' style={{ textAlign: "center" }}>{SETTINGS.NODE_INFO[type]?.title} <br/>
                                    <span className="market-sub-header">LIQUIDITY NODE</span><br /> 
                                    </h4>
                                    <Card.Img
                                        variant="top"
                                        className="mt-2"
                                        onClick={() => handleShow(type)}
                                        src={SETTINGS.NODE_INFO[type]?.imgUrl}
                                        style={{ width: "auto", height: "auto", maxHeight: "200px" }}
                                    />
                                </div>
                                {!isConnected ? (
                                    <div className='mt-1'>
                                        <div className='input-group' style={{ marginBottom: "0px" }}>
                                            <div className="input-group d-flex justify-content-center align-items-center">
                                                <Alert variant="secondary">
                                                    <div className='py-2 px-md-2 px-1 text-center' style={{ margin: "0px" }}>
                                                        <p>Connect wallet to buy {SETTINGS.NODE_INFO[type]?.title}</p>
                                                        <ConnectButton />
                                                    </div>
                                                </Alert>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='mt-1'>
                                          
                                    
                                        {buyAvailable ? (
                                            <div>

                                                {userLevel >= index ?(
                                                    <>
                                <div className="pokemon-card-footer" style={{ paddingTop: "6px" }}>
                                    <Row className='mb-2'>
                                        <Col xs={3} sm={2} className='offset-sm-3 offset-2 justify-content-center align-items-center me-2'>
                                            <Button
                                                variant="outline-secondary"
                                                className='buton buton-small'
                                                onClick={() => decrementQuantity(index)}
                                            >
                                                -
                                            </Button>
                                        </Col>
                                        <Col xs={2} sm={2} className="justify-content-center align-items-center">
                                            <div className='pokemon-card-title h5'>{quantities[index]}</div>
                                        </Col>
                                        <Col xs={3} sm={2}>
                                            <Button
                                                variant="outline-secondary"
                                                className='buton buton-small'
                                                onClick={() => incrementQuantity(index)}
                                            >
                                                +
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                    <div className="input-group d-flex justify-content-center align-items-center">
                                        <p className='mb-0'>Price:</p>
                                        <p className="mb-0 fw-bold" style={{marginLeft: "4px" }}>
                                            {parseFloat(SETTINGS.NODE_INFO[type].price) * quantities[index]} {SETTINGS.tokenSymbol}
                                        </p>
                                    </div>
                                        {index>0 && (  
                                            <div className="align-items-center" style={{ width: "100%" }}>
                                                <Select
                                                    options={currencyOptions}
                                                    value={currencyOptions.find(option => option.value === selectedCurrency)}
                                                    onChange={handleCurrencyChange}
                                                    styles={customSelectStyles}
                                                    menuPortalTarget={document.body}
                                                        menuPosition='fixed'
                                                />
                                            </div>)}
                                            <Button
                                                variant="primary"
                                                onClick={() => buyNFT(index)}
                                                className='buton mb-2 mt-2'
                                            >
                                                BUY NODE
                                            </Button>
                                                    </>
                                                ):(<center>
                                               
                                         
                                                   
                                                         
                                            <h4 className='mb-md-0 market-header' style={{ textAlign: "center" }}>
                                            <span className="market-sub-header">Unlock with: </span><br/>
                                            <img
                                                            src={SETTINGS.NODE_INFO[index]?.imgUrl}
                                                            className='tokenIconColor'
                                                            style={{ width: "40px", marginRight: "5px", borderRadius: "10px", marginTop: "10px", marginBottom: "10px" }}
                                                            alt={SETTINGS.NODE_INFO[index]?.title}
                                                        />
                                    
                                            <br /> {SETTINGS.NODE_INFO[index]?.title}
                                            </h4>       
                                    
                                                        
                                                </center>)}
                                             
                                                
                                             
                                            </div>
                                        ) : (
                                            <div>
                                                {index==0 ? (  
                                                    <div style={{position:"relative"}}>
                                                <div className='input-group' style={{ marginBottom: "10px" }}>
                                                    <input
                                                        type="text"
                                                        value={reffCode}
                                                        disabled={!isConnected}
                                                        onChange={(e) => _setReffCode(e.target.value)}
                                                        placeholder="Referral Code"
                                                        className='custom-select'
                                                    />
                                                    
                                                </div>
                                                <ReferralButton setReffCode={_setReffCode} />
</div>
                                                 ):(<center>
                                               
                                         
                                                   
                                                         
                                                    <h4 className='mb-md-0 market-header' style={{ textAlign: "center" }}>
                                                    <span className="market-sub-header">Unlock with: </span><br/>
                                                    <img
                                                                    src={SETTINGS.NODE_INFO[index]?.imgUrl}
                                                                    className='tokenIconColor'
                                                                    style={{ width: "40px", marginRight: "5px", borderRadius: "10px", marginTop: "10px", marginBottom: "10px" }}
                                                                    alt={SETTINGS.NODE_INFO[index]?.title}
                                                                />
                                            
                                                    <br /> {SETTINGS.NODE_INFO[index]?.title}
                                                    </h4>       
                                            
                                                                
                                                        </center>)}
                                                
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* Modal */}
                            <Modal show={show} onHide={handleClose} centered backdropClassName="custom-modal-backdrop">
                            <Modal.Header closeButton className='pt-0'>
                                <Modal.Title>
                                <h3 className='mb-0'>
                                    <Image src={modalContent?.imgUrl} rounded className="me-2" style={{ width: '80px', height: '80px' }} /> {modalContent?.level}
                                </h3>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='pt-0'>
                                <h5 className='mb-3'>Rewards:</h5>
                                {parseInt(modalContent?.refferalPercent) > 0 && (
                                <p className='small mb-4'>
                                    <b pill bg="info" className='me-1'>{modalContent?.refferalPercent} % referral </b> in 
                                    <b>
                                    <img 
                                        src={logoToken} 
                                        className='tokenIconColor' 
                                        style={{ width: "28px", marginRight: "4px", marginLeft: "4px", marginBottom: "3px" }} 
                                        alt={SETTINGS.tokenSymbol} 
                                    />
                                    {SETTINGS.tokenSymbol}
                                    </b>
                                </p>
                                )}

                                <hr />
                                {parseInt(modalContent?.refferalPercent) > 0 ? (
                                <div>
                                    <Row className='mb-3 mt-3 pt-2'>
                                    {/* First column for Craft */}
                                    <Col xs={8}>
                                        <p className='small'>
                                        Craft from
                                        {/*  <Image 
                                            src={modalContent??.imgUrl} 
                                            rounded 
                                            className="me-2 mb-2" 
                                            style={{ width: '50px', height: '50px', marginLeft: "10px" }} 
                                            title={modalContent?.hoverText}
                                        />
                                        from*/}
                                        {modalContent?.craftElements.map((imgMod, index) => {
                                            const craftElement = Object.values(SETTINGS.NODE_INFO).find(node => node?.imgUrl === imgMod);
                                            return (
                                            <Image 
                                                key={index} 
                                                src={imgMod} 
                                                rounded 
                                                className="ms-2 mb-2" 
                                                style={{ width: '50px', height: '50px' }} 
                                                title={craftElement?.hoverText}
                                            />
                                            );
                                        })}
                                        </p>
                                    </Col>

                                    {/* Second column for the Craft button */}
                                    <Col xs={4} className="d-flex align-items-center justify-content-end">
                                        <Button 
                                        variant="primary buton-secondary mt-2 d-flex align-items-center justify-content-center" 
                                        onClick={openCraft} 
                                        className='buton mb-4'
                                        >
                                        Craft
                                        </Button>
                                    </Col>
                                    </Row>

                                    <Row className='mb-3 mt-3 pt-2'>
                                    {/* First column for Buy */}
                                    <Col xs={8}>
                                        <p>
                                        Buy with
                                       {/* <Image 
                                            src={modalContent??.imgUrl} rounded className="me-2" style={{ width: '50px', height: '50px', marginLeft: "10px" }} title={modalContent?.hoverText} /> with*/}
                                        <b className='ms-2'> <img src={logoToken} 
                                            className='tokenIconColor' style={{ width: "28px", marginRight: "4px", marginLeft: "0px", marginBottom: "3px" }} alt={SETTINGS.tokenSymbol} />{modalContent?.price} {SETTINGS.tokenSymbol}
                                        </b>
                                        </p>
                                    </Col>

                                    {/* Second column for the Buy button */}
                                    <Col xs={4} className="d-flex align-items-center justify-content-end">
                                        <Button 
                                        variant="primary buton-secondary d-flex align-items-center justify-content-center" 
                                        onClick={openBuyNode} 
                                        className='buton mb-4'
                                        >
                                        Buy
                                        </Button>
                                    </Col>
                                    </Row>
                                </div>
                                ) : (
                                <div></div>
                                )}

                            </Modal.Body>
                            <Modal.Footer className='pb-1 pt-3'>
                                <Button variant="buton buton-secondary" className="buton" onClick={handleClose}>
                                Close
                                </Button>
                            </Modal.Footer>
                            </Modal>
                    </Col>
                ))}
                <h2 className="dashboard-header">Power Core Collection</h2> 
                <p className="hero-p sub-header">Activate Liquidity Nodes with Power Cores that recycle over time, reducing token supply and earning WaveCoin rewards</p> 
                {/* Buy CORE */}
                {nodeTypes.map((type, index) => (
                    <Col xs={12} md={6} lg={4} xl={3} className='mb-3' key={index}>
                        <div>
                            <div className='pokemon-card pokemon-card-height d-flex flex-column justify-content-between'>
                                <div className="pokemon-card-title pt-0 pb-1">
                                    <h4 className='mb-md-0 mb-3 market-header' style={{ textAlign: "center" }}> {SETTINGS.NODE_INFO[type]?.title}<br/> <span className="market-sub-header">Power Core</span></h4>
                                    <Card.Img variant="top mt-2" src={SETTINGS.NODE_INFO[type]?.imgUrl} style={{ width: "auto", height: "auto", maxHeight: "200px" }} />
                                </div>
                                {/* Amount */}
                                <div className="pokemon-card-footer" style={{ paddingTop: "6px" }}>
                                    <>
                                        <Row className='mb-2'>
                                            <Col xs={3} sm={2} className='offset-sm-3 offset-2 justify-content-center align-items-center me-2'>
                                                <Button variant="outline-secondary" className='buton buton-small' onClick={() => decrementQuantity1(index + 1)}>-</Button>
                                            </Col>
                                            <Col xs={2} sm={2} className="justify-content-center align-items-center">
                                                <div className='pokemon-card-title h5'>{quantities1[index + 1]}</div>
                                            </Col>
                                            <Col xs={3} sm={2}>
                                                <Button variant="outline-secondary" className='buton buton-small' onClick={() => incrementQuantity1(index + 1)}>+</Button>
                                            </Col>
                                        </Row>
                                    </>
                                </div>
                                {/* Buy */}
                                <div className=''>
                                    <div className="input-group d-flex justify-content-center align-items-center">
                                        <p className='mb-0'>Price:</p>
                                        <p className="mb-0 fw-bold"  style={{ marginLeft: "4px" }}>{parseFloat(SETTINGS.NODE_INFO[type].price) * quantities1[index + 1]} {SETTINGS.NODE_INFO[type].buyCurrency} </p>
                                    </div>
                                    {isConnected ? (
                                        <Button variant="primary" onClick={() => buyCard(type, index + 1)} className='buton mb-1'>
                                            BUY<img src={SETTINGS.NODE_INFO[type].logoToken} style={{ width: "20px", marginRight: "6px", marginLeft: "6px", height: "20px" }} alt={SETTINGS.tokenSymbol} />CORE
                                        </Button>
                                    ) : (
                                        <Button variant="primary" disabled className='buton buton-disabled mb-2 '>
                                            BUY<img src={SETTINGS.NODE_INFO[type].logoToken} style={{ width: "20px", marginRight: "6px", marginLeft: "6px", height: "20px" }} alt={SETTINGS.tokenSymbol} />CORE
                                        </Button>
                                    )}
                                    <center className='mb-1'>

                                    <span style={{ marginTop: "8px", display: "block", fontWeight: "400" }}>
                                            <small>Your balance:</small>
                                            <small className='fw-bold'  style={{ marginLeft: "4px" }}>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(tokenBalances[type])} {SETTINGS.NODE_INFO[type].buyCurrency}</small>
                                        </span>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}

            </Row>
            <SuccessModal show={modalShow} onHide={() => setModalShow(false)} />

        </div>
    );
};

export default Nodes;
